import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { StoreService } from '../../../store/app-store.service';
import { AppState } from '../../../store/app.reducer';
import * as act from './data-shared-services.actions';
import {
    DATA_SHARED_SERVICES_STORE,
    DataSharedServicesState,
} from './data-shared-services.state';

@Injectable()
export class DataSharedServicesStoreService extends StoreService {
    protected readonly STATE = DATA_SHARED_SERVICES_STORE;

    constructor(protected store: Store<AppState>) {
        super();
    }

    dispatchCadastroCentroDeCustoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosCentroDeCustoRecarregarAction(b));
    }
    dispatchCadastroGrupoCentroDeCustoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosGrupoCentroDeCustoRecarregarAction(b));
    }
    dispatchCadastroFilialAction(b: boolean) {
        this.dispatchAction(new act.CadastrosFilialRecarregarAction(b));
    }
    dispatchCadastroColaboradorAction(b: boolean) {
        this.dispatchAction(new act.CadastrosColaboradorRecarregarAction(b));
    }
    dispatchCadastroFornecedorAction(b: boolean) {
        this.dispatchAction(new act.CadastrosFornecedorRecarregarAction(b));
    }
    dispatchCadastroTipoDeFornecedorAction(b: boolean) {
        this.dispatchAction(
            new act.CadastrosTipoDeFornecedorRecarregarAction(b)
        );
    }
    dispatchCadastroTipoDeContratoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosTipoDeContratoRecarregarAction(b));
    }
    dispatchCadastroFornecedorContratoAction(b: boolean) {
        this.dispatchAction(
            new act.CadastrosFornecedorContratoRecarregarAction(b)
        );
    }
    dispatchCadastroProdutoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosProdutoRecarregarAction(b));
    }
    dispatchCadastroProjetoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosProjetoRecarregarAction(b));
    }
    dispatchCadastroGrupoDeProdutoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosGrupoDeProdutoRecarregarAction(b));
    }
    dispatchCadastroCategoriaDeProdutoAction(b: boolean) {
        this.dispatchAction(
            new act.CadastrosCategoriaDeProdutoRecarregarAction(b)
        );
    }
    dispatchCadastroTipoDeProdutoAction(b: boolean) {
        this.dispatchAction(new act.CadastrosTipoDeProdutoRecarregarAction(b));
    }
    dispatchCadastroCondicaoDePagamentoAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroCondicaoDePagamentoRecarregarAction(b)
        );
    }

    dispatchCadastroCondicaoDePagamentoItemAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroCondicaoDePagamentoItemRecarregarAction(b)
        );
    }

    dispatchCadastroExportacaoDeRelatorioAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroExportacaoDeRelatorioRecarregarAction(b)
        );
    }
    dispatchCadastroExportacaoDeRelatorioItemAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroExportacaoDeRelatorioItemRecarregarAction(b)
        );
    }

    dispatchCadastroMapeamentoDeRateioAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroMapeamentoDeRateioRecarregarAction(b)
        );
    }

    dispatchCadastroMapeamentoDeRateioItemAction(b: boolean) {
        this.dispatchAction(
            new act.CadastroMapeamentoDeRateioItemRecarregarAction(b)
        );
    }

    dispatchComprasFormaDePagamentoAction(b: boolean) {
        this.dispatchAction(new act.ComprasFormaDePagamentoRecarregarAction(b));
    }
    dispatchComprasRegraDeAprovacaoAction(b: boolean) {
        this.dispatchAction(new act.ComprasRegraDeAprovacaoRecarregarAction(b));
    }
    dispatchComprasTipoDeCompraAction(b: boolean) {
        this.dispatchAction(new act.ComprasTipoDeCompraRecarregarAction(b));
    }
    dispatchComprasRequisicaoAbertasAction(b: boolean) {
        this.dispatchAction(
            new act.ComprasRequisicaoAbertasRecarregarAction(b)
        );
    }
    dispatchComprasOrdemAbertasAction(b: boolean) {
        this.dispatchAction(new act.ComprasOrdemAbertasRecarregarAction(b));
    }
    dispatchComprasOrdemEncerradasAction(b: boolean) {
        this.dispatchAction(new act.ComprasOrdemEncerradasRecarregarAction(b));
    }
    dispatchComprasCotacoesAction(b: boolean) {
        this.dispatchAction(new act.ComprasCotacoesRecarregarAction(b));
    }
    dispatchComprasPedidoAbertosAction(b: boolean) {
        this.dispatchAction(new act.ComprasPedidoAbertosRecarregarAction(b));
    }
    dispatchComprasPedidoEncerradosAction(b: boolean) {
        this.dispatchAction(new act.ComprasPedidoEncerradosRecarregarAction(b));
    }
    dispatchComprasRecebimentosPendentesAction(b: boolean) {
        this.dispatchAction(
            new act.ComprasRecebimentoPendentesRecarregarAction(b)
        );
    }
    dispatchComprasRecebimentosProcessadosAction(b: boolean) {
        this.dispatchAction(
            new act.ComprasRecebimentoProcessadosRecarregarAction(b)
        );
    }
    dispatchComprasOrdemSugestoesAction(b: boolean) {
        this.dispatchAction(new act.ComprasOrdemSugestoesRecarregarAction(b));
    }
    dispatchComprasOrdemProdutosAction(b: boolean) {
        this.dispatchAction(new act.ComprasOrdemProdutosRecarregarAction(b));
    }
    dispatchComprasPlanejamentoAction(b: boolean) {
        this.dispatchAction(new act.ComprasPlanejamentoRecarregarAction(b));
    }
    dispatchComprasMotivoAction(b: boolean) {
        this.dispatchAction(new act.ComprasMotivoRecarregarAction(b));
    }
    dispatchComprasNivelDeAprovacaoAction(b: boolean) {
        this.dispatchAction(new act.ComprasNivelDeAprovacaoRecarregarAction(b));
    }

    dispatchEstoqueAlmoxarifadoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueAlmoxarifadoRecarregarAction(b));
    }
    dispatchEstoqueMapeamentoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueMapeamentoRecarregarAction(b));
    }
    dispatchEstoqueSecaoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueSecaoRecarregarAction(b));
    }
    dispatchEstoqueClassificacaoAuxiliarAction(b: boolean) {
        this.dispatchAction(
            new act.EstoqueClassificacaoAuxiliarRecarregarAction(b)
        );
    }
    dispatchEstoqueEntradaAction(b: boolean) {
        this.dispatchAction(new act.EstoqueEntradaRecarregarAction(b));
    }
    dispatchEstoqueSaidaAction(b: boolean) {
        this.dispatchAction(new act.EstoqueSaidaRecarregarAction(b));
    }
    dispatchEstoqueTransferenciaAction(b: boolean) {
        this.dispatchAction(new act.EstoqueTransferenciaRecarregarAction(b));
    }
    dispatchEstoqueSaldosAction(b: boolean) {
        this.dispatchAction(new act.EstoqueSaldosRecarregarAction(b));
    }
    dispatchEstoqueExpedicaoPendentesAction(b: boolean) {
        this.dispatchAction(
            new act.EstoqueExpedicaoPendentesRecarregarAction(b)
        );
    }
    dispatchEstoqueExpedicaoProcessadosAction(b: boolean) {
        this.dispatchAction(
            new act.EstoqueExpedicaoProcessadosRecarregarAction(b)
        );
    }
    dispatchEstoqueMotivoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueMotivoRecarregarAction(b));
    }
    dispatchEstoqueTipoDeRequisicaoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueTipoDeRequisicaoRecarregarAction(b));
    }
    dispatchEstoqueRegraDeAprovacaoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueRegraDeAprovacaoRecarregarAction(b));
    }
    dispatchEstoqueNivelDeAprovacaoAction(b: boolean) {
        this.dispatchAction(new act.EstoqueNivelDeAprovacaoRecarregarAction(b));
    }

    dispatchCrmClienteAction(b: boolean) {
        this.dispatchAction(new act.CrmClienteRecarregarAction(b));
    }
    dispatchCrmCategoriaDeClienteAction(b: boolean) {
        this.dispatchAction(new act.CrmCategoriaDeClienteRecarregarAction(b));
    }
    dispatchCrmTipoDeClienteAction(b: boolean) {
        this.dispatchAction(new act.CrmTipoDeClienteRecarregarAction(b));
    }
    dispatchCrmSituacaoDeClienteAction(b: boolean) {
        this.dispatchAction(new act.CrmSituacaoDeClienteRecarregarAction(b));
    }
    dispatchCrmGrupoDeClienteAction(b: boolean) {
        this.dispatchAction(new act.CrmGrupoDeClienteRecarregarAction(b));
    }

    dispatchFinanceiroFormaDePagamentoAction(b: boolean) {
        this.dispatchAction(
            new act.FinanceiroFormaDePagamentoRecarregarAction(b)
        );
    }

    dispatchFinanceiroClassificacaoAuxiliarAction(b: boolean) {
        this.dispatchAction(
            new act.FinanceiroClassificacaoAuxiliarRecarregarAction(b)
        );
    }
    dispatchFinanceiroAgenciaAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroAgenciaRecarregarAction(b));
    }
    dispatchFinanceiroCustoABCAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCustoABCRecarregarAction(b));
    }
    dispatchFinanceiroCaixaBancosAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCaixaBancosRecarregarAction(b));
    }
    dispatchFinanceiroContaCorrenteAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroContaCorrenteRecarregarAction(b));
    }
    dispatchFinanceiroPlanoDeContasAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroPlanoDeContasRecarregarAction(b));
    }
    dispatchFinanceiroCrAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCrRecarregarAction(b));
    }
    dispatchFinanceiroCrBxAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCrBxRecarregarAction(b));
    }
    dispatchFinanceiroCrPrejuizosAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCrPrejuizosRecarregarAction(b));
    }
    dispatchFinanceiroCpAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCpRecarregarAction(b));
    }
    dispatchFinanceiroCpBxAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCpBxRecarregarAction(b));
    }
    dispatchFinanceiroItemLancamentoAction(b: boolean) {
        this.dispatchAction(
            new act.FinanceiroItemLancamentoRecarregarAction(b)
        );
    }

    dispatchFinanceiroCarteiraAction(b: boolean) {
        this.dispatchAction(new act.FinanceiroCarteiraRecarregarAction(b));
    }

    dispatchVendasFormaDePagamentoAction(b: boolean) {
        this.dispatchAction(new act.VendasFormaDePagamentoRecarregarAction(b));
    }
    dispatchVendasFaturaAbertasAction(b: boolean) {
        this.dispatchAction(new act.VendasFaturaAbertasRecarregarAction(b));
    }
    dispatchVendasFaturaProcessadasAction(b: boolean) {
        this.dispatchAction(new act.VendasFaturaProcessadasRecarregarAction(b));
    }
    dispatchVendasFaturaCanceladasAction(b: boolean) {
        this.dispatchAction(new act.VendasFaturaCanceladasRecarregarAction(b));
    }
    dispatchVendasOrdemAbertasAction(b: boolean) {
        this.dispatchAction(new act.VendasOrdemAbertasRecarregarAction(b));
    }
    dispatchVendasOrdemProcessadasAction(b: boolean) {
        this.dispatchAction(new act.VendasOrdemProcessadasRecarregarAction(b));
    }
    dispatchVendasOrdemCanceladasAction(b: boolean) {
        this.dispatchAction(new act.VendasOrdemCanceladasRecarregarAction(b));
    }
    dispatchVendasPedidoAbertosAction(b: boolean) {
        this.dispatchAction(new act.VendasPedidoAbertosRecarregarAction(b));
    }
    dispatchVendasPedidoProcessadosAction(b: boolean) {
        this.dispatchAction(new act.VendasPedidoProcessadosRecarregarAction(b));
    }
    dispatchVendasPedidoCanceladosAction(b: boolean) {
        this.dispatchAction(new act.VendasPedidoCanceladosRecarregarAction(b));
    }
    dispatchVendasNotaServicoPendentesAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaServicoPendentesRecarregarAction(b)
        );
    }
    dispatchVendasNotaServicoEmitidasAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaServicoEmitidasRecarregarAction(b)
        );
    }
    dispatchVendasNotaServicoCanceladasAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaServicoCanceladasRecarregarAction(b)
        );
    }
    dispatchVendasNotaProdutoPendentesAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaProdutoPendentesRecarregarAction(b)
        );
    }
    dispatchVendasNotaProdutoEmitidasAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaProdutoEmitidasRecarregarAction(b)
        );
    }
    dispatchVendasNotaProdutoCanceladasAction(b: boolean) {
        this.dispatchAction(
            new act.VendasNotaProdutoCanceladasRecarregarAction(b)
        );
    }

    getCadastroCentroDeCusto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroCentroDeCusto
            )
        );
    }

    getCadastroGrupoCentroDeCusto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroGrupoCentroDeCusto
            )
        );
    }

    getCadastroFilial() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.cadastroFilial)
        );
    }
    getCadastroColaborador() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroColaborador
            )
        );
    }
    getCadastroFornecedor() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.cadastroFornecedor)
        );
    }
    getCadastroTipoDeFornecedor() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.cadastroTipoDeFornecedor
            )
        );
    }
    getCadastroTipoDeContrato() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroTipoDeContrato
            )
        );
    }
    getCadastroProduto() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.cadastroProduto)
        );
    }
    getCadastroProjeto() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.cadastroProjeto)
        );
    }
    getCadastroGrupoDeProduto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroGrupoDeProduto
            )
        );
    }
    getCadastroCategoriaDeProduto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.cadastroCategoriaDeProduto
            )
        );
    }
    getCadastroTipoDeProduto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.cadastroTipoDeProduto
            )
        );
    }
    getCadastroCondicaoDePagamento() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.cadastroCondicaoDePagamento
            )
        );
    }

    getComprasFormaDePagamento() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasFormaDePagamento
            )
        );
    }
    getComprasRegraDeAprovacao() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasRegraDeAprovacao
            )
        );
    }
    getComprasTipoDeCompra() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasTipoDeCompra
            )
        );
    }
    getComprasRequisicaoProduto() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasRequisicaoAbertas
            )
        );
    }
    getComprasOrdemAbertas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasOrdemAbertas
            )
        );
    }
    getComprasOrdemEncerradas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasOrdemEncerradas
            )
        );
    }
    getComprasCotacoes() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.comprasCotacoes)
        );
    }
    getComprasPedidoAbertos() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasPedidoAbertos
            )
        );
    }
    getComprasPedidoEncerrados() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasPedidoEncerrados
            )
        );
    }
    getComprasRecebimentoPendentes() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasRecebimentoPendentes
            )
        );
    }
    getComprasRecebimentoProcessados() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasRecebimentoProcessados
            )
        );
    }
    getComprasOrdemSugestoes() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasOrdemSugestoes
            )
        );
    }
    getComprasOrdemProdutos() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasOrdemProdutos
            )
        );
    }
    getComprasPlanejamento() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.comprasPlanejamento
            )
        );
    }
    getComprasMotivo() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.comprasMotivo)
        );
    }

    getComprasNivelDeCompra() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.comprasNivelDeAprovacao
            )
        );
    }

    getEstoqueAlmoxarifado() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.estoqueAlmoxarifado
            )
        );
    }
    getEstoqueSecao() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueSecao)
        );
    }
    getEstoqueClassificacaoAuxiliar() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueClassificacaoAuxiliar
            )
        );
    }
    getEstoqueEntrada() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueEntrada)
        );
    }
    getEstoqueSaida() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueSaida)
        );
    }
    getEstoqueTransferencia() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.estoqueTransferencia
            )
        );
    }
    getEstoqueAjuste() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueAjuste)
        );
    }
    getEstoqueSaldos() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueSaldos)
        );
    }
    getEstoqueExpedicaoPendentes() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueExpedicaoPendentes
            )
        );
    }
    getEstoqueExpedicaoProcessados() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueExpedicaoProcessados
            )
        );
    }
    getEstoqueMotivo() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueMotivo)
        );
    }
    getEstoqueTipoDeRequisicao() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueTipoDeRequisicao
            )
        );
    }
    getEstoqueRegraDeAprovacao() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueRegraDeAprovacao
            )
        );
    }

    getEstoqueNivelDeAprovacao() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.estoqueNivelDeAprovacao
            )
        );
    }

    getEstoqueMapeamento() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.estoqueMapeamento)
        );
    }

    getCrmCliente() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.crmCliente)
        );
    }

    getCrmCategoriaDeCliente() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.crmCategoriaDeCliente
            )
        );
    }
    getCrmTipoDeCliente() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.crmTipoDeCliente)
        );
    }
    getCrmSituacaoDeCliente() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.crmSituacaoDeCliente
            )
        );
    }
    getCrmGrupoDeCliente() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.crmGrupoDeCliente)
        );
    }

    getFinanceiroFormaDePagamento() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.financeiroFormaDePagamento
            )
        );
    }
    getFinanceiroClassificacaoAuxiliar() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.financeiroClassificacaoAuxiliar
            )
        );
    }
    getFinanceiroAgencia() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroAgencia)
        );
    }
    getFinanceiroCustoABC() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroCustoABC)
        );
    }
    getFinanceiroCaixaBancos() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.financeiroCaixaBancos
            )
        );
    }
    getFinanceiroContaCorrente() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.financeiroContaCorrente
            )
        );
    }
    getFinanceiroPlanoDeContas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.financeiroPlanoDeContas
            )
        );
    }
    getFinanceiroCr() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroCr)
        );
    }
    getFinanceiroCrBx() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroCrBx)
        );
    }
    getFinanceiroCrPrejuizos() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.financeiroCrPrejuizos
            )
        );
    }

    getFinanceiroCp() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroCp)
        );
    }
    getFinanceiroCpBx() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.financeiroCpBx)
        );
    }
    getFinanceiroItemLancamento() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.financeiroItemLancamento
            )
        );
    }

    getVendasFaturaAbertas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasFaturaAbertas
            )
        );
    }
    getVendasFaturaProcessadas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasFaturaProcessadas
            )
        );
    }
    getVendasFaturaCanceladas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasFaturaCanceladas
            )
        );
    }
    getVendasOrdemAbertas() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.vendasOrdemAbertas)
        );
    }
    getVendasOrdemProcessadas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasOrdemProcessadas
            )
        );
    }
    getVendasOrdemCanceladas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasOrdemCanceladas
            )
        );
    }
    getVendasPedidoAbertos() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasPedidoAbertos
            )
        );
    }
    getVendasPedidoProcessados() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasPedidoProcessados
            )
        );
    }
    getVendasPedidoCancelados() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) => state.vendasPedidoCancelados
            )
        );
    }
    getVendasNotaServicoPendentes() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaServicoPendentes
            )
        );
    }
    getVendasNotaServicoEmitidas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaServicoEmitidas
            )
        );
    }
    getVendasNotaServicoCanceladas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaServicoCanceladas
            )
        );
    }
    getVendasNotaProdutoPendentes() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaProdutoPendentes
            )
        );
    }
    getVendasNotaProdutoEmitidas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaProdutoEmitidas
            )
        );
    }
    getVendasNotaProdutoCanceladas() {
        return this.storeSelectFeatureState().pipe(
            select(
                (state: DataSharedServicesState) =>
                    state.vendasNotaProdutoCanceladas
            )
        );
    }

    getError() {
        return this.storeSelectFeatureState().pipe(
            select((state: DataSharedServicesState) => state.error),
            filter((r) => r)
        );
    }
}
