import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { FullScreenModalComponent } from './full-screen-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxPopupModule,
        DxScrollViewModule
    ],
    declarations: [FullScreenModalComponent],
    exports: [FullScreenModalComponent],
})
export class FullScreenModalModule {}
