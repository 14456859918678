import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'gestio-full-screen-modal',
    templateUrl: './full-screen-modal.component.html',
    styleUrls: ['./full-screen-modal.component.scss'],
})
export class FullScreenModalComponent implements OnInit {
    @Input() title: string | null = null;
    @Input() subTitle: string | null = null;
    @Input() isVisible: boolean = false;

    @Output() onClose = new EventEmitter<any>();

    animation = {
        show: {
            type: 'slideIn',
            direction: 'bottom',
            duration: 400,
        },
        hide: {
            type: 'slideOut',
            direction: 'bottom',
            duration: 400,
        },
    };
    constructor() {}

    ngOnInit() {}

    onContentReady(event) {
        var contentElement = event.component.content();
        contentElement.style.padding = '0px';
        contentElement.style.boxShadow = 'none';
    }

    actionFechar() {
        this.onClose.emit();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.onClose.emit();
        }
    }
}
