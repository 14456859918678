import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DxFileUploaderModule, DxProgressBarModule } from 'devextreme-angular';
import { DevextremeComponentsModule } from '../devextreme-components.module';
import { MaterialComponentsModule } from '../material-components.module';
import { ButtonsComponentsModule } from '../ui-components/components/buttons/buttons-components.module';
import { CmpContentAreaGestioComponent } from './components/areas/content-area/cmp-content-area.component';
import { CmpDetailSectionGestioComponent } from './components/areas/detail-section/cmp-detail-section.component';
import { FirstStepsContentComponent } from './components/areas/first-steps-content/first-steps-content.component';
import { CmpFullPageAreaGestioComponent } from './components/areas/full-page-area/cmp-full-page-area.component';
import { CmpPageAreaGestioComponent } from './components/areas/page-area/cmp-page-area.component';
import { AttachFilesProductComponent } from './components/attach-files-product/attach-files-product.component';
import { AttachFilesModule } from './components/attach-files/attach-files.module';
import { ButtonsComponentsGestioModule } from './components/buttons/buttons-components.module';
import { ChartComponentsGestioModule } from './components/chart/chart-components.module';
import { CheckboxesComponentsGestioModule } from './components/checkbox/checkboxes-components.module';
import { CommentsComponent } from './components/comments/comments.component';
import { DatagridComponentsGestioModule } from './components/datagrid/datagrid-components.module';
import { DateboxsComponentsGestioModule } from './components/datebox/dateboxs-components.module';
import { CmpDetailSectionNavigationGestioComponent } from './components/detail-section-navigation/cmp-detail-section-navigation.component';
import { FooterComponentsGestioModule } from './components/footer/footer-components.module';
import { FormModalModule } from './components/modal/form/form-modal.module';
import { FullScreenModalModule } from './components/modal/full-screen/full-screen-modal.module';
import { NumberboxsComponentsGestioModule } from './components/numberbox/numberboxs-components.module';
import { RadioGroupComponentsGestioModule } from './components/radio-group/radio-group-components.module';
import { SelectboxsComponentsGestioModule } from './components/selectbox/selectboxs-components.module';
import { SwitchesComponentsGestioModule } from './components/switch/switches-components.module';
import { TabsGestioModule } from './components/tabs/tabs.module';
import { TagboxDisabledLabelComponent } from './components/tagbox-disabled-label/tagbox-disabled-label.component';
import { TextareasComponentsGestioModule } from './components/textarea/textareas-components.module';
import { TextboxGenerateProductCodeGestioModule } from './components/textbox-generate-product-code/textbox-generate-product-code.module';
import { TextboxSearchGestioModule } from './components/textbox-search/textbox-search.module';
import { TextboxsComponentsGestioModule } from './components/textbox/textboxs-components.module';
import { CmpTitleAreaGestioComponent } from './components/title-area/cmp-title-area.component';
import { CopyToClipboardModule } from './directives/copy-to-clipboard.module';
import { ScrollToDirective } from './directives/scroll-to.directive';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        DevextremeComponentsModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ButtonsComponentsModule,
        DxFileUploaderModule,
        DxProgressBarModule,
        FormModalModule,
    ],
    declarations: [
        FirstStepsContentComponent,
        CmpContentAreaGestioComponent,
        CmpPageAreaGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpTitleAreaGestioComponent,
        CommentsComponent,
        AttachFilesProductComponent,
        TagboxDisabledLabelComponent,
        CmpDetailSectionNavigationGestioComponent,
        ScrollToDirective,
    ],
    exports: [
        CmpContentAreaGestioComponent,
        FirstStepsContentComponent,
        CmpPageAreaGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpTitleAreaGestioComponent,
        CmpDetailSectionNavigationGestioComponent,
        DatagridComponentsGestioModule,
        ButtonsComponentsGestioModule,
        NumberboxsComponentsGestioModule,
        TextareasComponentsGestioModule,
        CheckboxesComponentsGestioModule,
        SwitchesComponentsGestioModule,
        FooterComponentsGestioModule,
        RadioGroupComponentsGestioModule,
        TabsGestioModule,
        TextboxSearchGestioModule,
        TextboxsComponentsGestioModule,
        TextboxGenerateProductCodeGestioModule,
        SelectboxsComponentsGestioModule,
        ScrollToDirective,
        ChartComponentsGestioModule,
        CommentsComponent,
        AttachFilesProductComponent,
        TagboxDisabledLabelComponent,
        DateboxsComponentsGestioModule,
        FullScreenModalModule,
        AttachFilesModule,
        FormModalModule,
        CopyToClipboardModule,
    ],
})
export class UIComponentsGestioModule {}
